<template>
  <section id="reference">
    <div id="inside">
      <h1>100% ZADOWOLONYCH KLIENTÓW</h1>
      <h2>Referencje potwierdzające najwyższe standardy</h2>
      <h3>
        &emsp;Przez cały okres naszej działalności wykonaliśmy już niemal
        <b>100 000 &#13217;</b> izolacji natryskowych, ale również zdobyliśmy
        ogromną ilość zadowolonych klientów. Zachęcamy do zapoznainia się z
        referencjami potwierdzającymi wykonanie inwestycji na najwyższym
        poziomie.
      </h3>
      <section id="imageList">
        <img
          v-for="n in referenceItems"
          :key="n"
          :src="require('@/assets/images/reference/' + n + '.png')"
          v-on:click="openGallery(n)"
        />
      </section>
    </div>
    <section id="fullScreenImage">
      <i class="icon icon-left-open-mini" v-on:click="prevImage()">
        &#xe804;
      </i>
      <img
        :src="
          require('@/assets/images/reference/' + this.selectedImage + '.png')
        "
      />
      <i class="icon icon-right-open-mini" v-on:click="nextImage()">
        &#xe803;
      </i>
    </section>
  </section>
</template>

<script>
export default {
  created() {
    for (let i = 1; i < 10; i++) this.images.push(i + ".png");

    document.addEventListener("click", (event) => {
      if (event.target == document.getElementById("fullScreenImage"))
        this.closeGallery();
    });

    document.addEventListener("keydown", (event) => {
      let keys = { left: 37, right: 39, esc: 27 };

      switch (event.keyCode) {
        case keys.left:
          this.prevImage();
          break;
        case keys.right:
          this.nextImage();
          break;
        case keys.esc:
          this.closeGallery();
          break;
      }
    });
  },
  data() {
    return {
      images: [],
      referenceItems: 9,
      selectedImage: 1,
    };
  },
  methods: {
    openGallery: function (imgIndex) {
      this.selectedImage = imgIndex;
      document.getElementById("fullScreenImage").style.display = "flex";
    },
    closeGallery: function () {
      document.getElementById("fullScreenImage").style.display = "none";
    },
    nextImage: function () {
      if (this.selectedImage < this.referenceItems) this.selectedImage++;
      else this.selectedImage = 1;
    },
    prevImage: function () {
      if (this.selectedImage > 1) this.selectedImage--;
      else this.selectedImage = this.referenceItems;
    },
  },
};
</script>

<style scoped>
section#reference {
  display: flex;
  flex-direction: column;
  align-items: center;
}

section#reference div#inside {
  width: 60%;
  margin: 120px 0 0 0;
}

section#reference div#inside h1 {
  font-size: 1.4rem;
  color: #6ba421;
  margin: 60px 0 30px 0;
  font-weight: 600;
}

section#reference div#inside h2 {
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0 0 30px 0;
  font-weight: 600;
  width: 70%;
}

section#reference div#inside h3 {
  font-size: 1rem;
  margin: 0 0 30px 0;
  width: 70%;
  line-height: 1.2rem;
  text-align: justify;
}

section#reference div#inside h3 b {
  font-weight: 600;
}

section#reference div#inside section#imageList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 0 60px 0;
}

section#reference div#inside section#imageList img {
  width: 150px;
  height: auto;
  margin: 20px 20px 0 0;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  transition: 0.3s ease;
}

section#reference div#inside section#imageList img:hover {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

section#reference section#fullScreenImage {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

section#reference section#fullScreenImage i.icon-left-open-mini {
  color: #fff;
  font-size: 50px;
  cursor: pointer;
  transition: 0.3s ease;
}

section#reference section#fullScreenImage i.icon-left-open-mini:hover {
  color: #aaa;
}

section#reference section#fullScreenImage i.icon-right-open-mini {
  color: #fff;
  font-size: 50px;
  cursor: pointer;
  transition: 0.3s ease;
}

section#reference section#fullScreenImage i.icon-right-open-mini:hover {
  color: #aaa;
}

section#reference section#fullScreenImage img {
  max-width: 80%;
  max-height: 80%;
}

@media only screen and (max-width: 1280px) {
  section#reference div#inside {
    width: 80%;
    margin: 60px 0 0 0;
  }

  section#reference div#inside h2 {
    font-size: 1.6rem;
    line-height: 2rem;
    width: 100%;
  }

  section#reference div#inside h3 {
    width: 100%;
  }

  section#reference div#inside section#imageList {
    flex-direction: column;
  }

  section#reference div#inside section#imageList img {
    width: 100%;
    min-height: auto;
  }
}
</style>
